@use '@/styles/utils/mixins.scss' as *;

.offeringWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.75rem;

  @include tab() {
    grid-template-columns: repeat(1, 1fr);
  }

  .offeringContentContainer {
    display: flex;
    gap: 1.25rem;
    flex-direction: column;
  }

  .offeringContentDetails {
    width: 100%;
  }

  .offeringList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem;

    padding-top: 9.375rem;
    flex: 1 1 auto;
    height: 100%;
    max-height: calc(100vh - 7.5rem);
    overflow-y: auto;
    scroll-behavior: smooth;

    @include tab() {
      height: auto;
      overflow-y: visible;
      overflow-x: hidden;
      max-height: 100%;
      padding-top: 0rem;
    }
  }

  .offeringCard {
    height: 100%;
    border-radius: 1.25rem;
    padding: 2.5rem 1.875rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    background-color: $white;
    border: 0 solid $white;
    -webkit-box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
    -moz-box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
    box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
    transition: all 0.2s ease-in-out;

    .offeringCardBody {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }

    .description {
      color: $neutral08;
    }
  }
}
